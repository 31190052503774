import styled from 'styled-components'

export const Link = styled.a`
  display: flex;
  align-items: center;
  gap: 4px;
  font-size: 12px;
  text-decoration: underline;
  color: #757575;
  cursor: pointer;
  transition: all 0.25s ease;
  &:hover,
  &:focus-within {
    color: yellow;
  }
`