import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Cronos, CronosTestnet, DAppProvider } from '@usedapp/core'

const config = {
  //readOnlyChainId: CronosTestnet.chainId,
  readOnlyChainId: Cronos.chainId,
  readOnlyUrls: {
    [CronosTestnet.chainId]: 'https://evm-t3.cronos.org/',
    [Cronos.chainId]: 'https://evm.cronos.org/'
    //[CronosTestnet.chainId]: 'http://localhost:8545/'
  },
  multicallAddresses: {
    [Cronos.chainId]: "0x0fA4d452693F2f45D28c4EC4d20b236C4010dA74"
  }
}

//if (process.env.NODE_ENV === 'development') {
//  config.readOnlyChainId = CronosTestnet.chainId
//}

console.log(config);
ReactDOM.render(
  <React.StrictMode>
    <DAppProvider config={config}>
      <App />
    </DAppProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
