import { Routes, Route, BrowserRouter } from 'react-router-dom';
import TopBar from './components/TopBar';
import Container from 'react-bootstrap/Container';
import Home from './pages/Home';
import Collection from './pages/Collection';
import Admin from './pages/Admin';
import NFTs from './pages/NFTs';
import Drops from './pages/Drops';
import ScrollToTop from './components/ScrollToTop'

function App() {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <TopBar />
      <Container fluid>
        <Routes>
          <Route path="/" element={<Home/>} />
          <Route path="/drops" element={<Drops/>} />
          <Route path="/nfts" element={<NFTs/>} />
          <Route path="/collection/:name" element={<Collection/>} />
          <Route path="/admin/:name" element={<Admin/>} />
        </Routes>
      </Container>
    </BrowserRouter>
  );
}

export default App;
