import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import ViewerModal from '../components/ViewerModal';
import Spinner from 'react-bootstrap/Spinner';
import { useEthers } from '@usedapp/core';
import ContractData from '../abi';

import Select from 'react-select'

import BackButton from '../components/BackButton';

import IPFSGatewayTools from '@pinata/ipfs-gateway-tools/dist/browser'

const gatewayTools = new IPFSGatewayTools();
const gateway = 'https://mygateway.mypinata.cloud';

import { Interface } from '@ethersproject/abi';
import { Contract } from '@ethersproject/contracts';
import { formatUnits } from "@ethersproject/units";

const isDev = (process.env.NODE_ENV === 'development');

const getNFTs = async (account, signer, nftState, setNfts) => {
  // let nfts = []
  let nfts = [...nftState];

  for (const nft of Object.keys(ContractData)) {
    let data = {
      address: ( isDev ? ContractData[nft].testnetAddress : ContractData[nft].address),
      interface: new Interface(ContractData[nft].abi),
      cid: ContractData[nft].cid,
      hasVideo: ContractData[nft].hasVideo
    }

    let readContract = new Contract(data.address, data.interface, signer);

    try {
      let count = formatUnits(await readContract.balanceOf(account), 0);
      let ids = [];

      if (count > 0) {
        try {
          await readContract.tokenOfOwnerByIndex(account, 0);
        } catch (error) {
          ids = await readContract.walletOfOwner(account);
        }
      }

      for (let i = 0; i < count; i++) {
        let id;
        if (ids.length === 0) {
          try {
            id = await readContract.tokenOfOwnerByIndex(account, i);
          } catch (error) {
            continue;
          }
        } else {
          id = ids[i];
        }

        const uri = await (async () => {
          return await readContract.tokenURI(id)
        })();

        const checkedUri = (() => {
          if (gatewayTools.containsCID(uri) && !uri.startsWith('ar')) {
            return gatewayTools.convertToDesiredGateway(uri, gateway);
          }
          return uri;
        })();

        let json = '';

        if (data.cid === 'QmfGaLtGorqShUd9MjtjZSUcwgtsLJDU4TDpNvjFSEQijD') {
          json = await (await fetch(`${checkedUri}/1.json`)).json();
        } else {
          json = await (await fetch(`${checkedUri}/${formatUnits(id, 0)}.json`)).json();
        }

        let image;

        if (json.image.startsWith('ipfs')) {
          image = `${gateway}/ipfs/${json.image.substring(7)}`;
        }

        let nftObject = {
          image,
          address: data.address.toLowerCase(),
          name: json.name
        }

        if (data.hasVideo) {
          nftObject.video = `${checkedUri}/${formatUnits(id, 0)}.mp4`
        }

        nfts.push(nftObject)
        setNfts([...nfts])
      }
    } catch (e) {
      console.log(e);
    }
      // json = await (await fetch(checkedUri)).json();
      // console.log("JSON", json    }
  }

  return await Promise.all(nfts)
}

export default function NFTs() {
  const { account, library, chainId } = useEthers();
  const [nfts, setNfts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isCronosNetwork, setIsCronosNetwork] = useState(true);
  const [filter, setFilter] = useState("");

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const [viewNft, setViewNft] = useState({});

  const options = []

  for (const nft of Object.keys(ContractData)) {
    options.push({
      value: isDev ? ContractData[nft].testnetAddress : ContractData[nft].address,
      label: ContractData[nft].title
    })
  }

  options.sort((a,b) => {
    return a.label.localeCompare(b.label)
  })


  useEffect(() => {
    setIsCronosNetwork(!chainId || chainId === ((process.env.NODE_ENV === 'development') ? 338 : 25))
  }, [chainId])

  useEffect(async () => {
    if (account && library) {
      let nftFetch = (await getNFTs(account, library.getSigner(), nfts, setNfts)).filter(nft => !!nft);
      setIsLoading(false);
      //setNfts([...nftFetch]);
    }
  },[account])

  const handleClick = nft => {
    setShow(true)
    setViewNft(nft)
  }

  const handleChange = event => {
    const value = event.value.toLowerCase()
    console.log(nfts)
    console.log("previous value is", filter)
    console.log("setting value", value)
    setFilter(value)
  }

  return(
    <>
    {!isCronosNetwork || !account ?
    <Row className="justify-content-center mt-3">
      <Col sm={8}>
        <Alert variant='danger'>
          Connect {!account ? 'your wallet' : 'to Cronos Network' }
        </Alert>
      </Col>
    </Row>
    : ''
    }
    <BackButton/>
    <Row className="justify-content-center">
      <Col xs={12} sm={8}>
      <Select options={options} onChange={handleChange} />
      </Col>
      <Col xs={12} sm={8} className="mt-5">
        {!nfts.length && !isLoading
          ? <Row className="justify-content-center mt-5"><h3 style={{textAlign: 'center'}}>There's nothing here! 👻</h3></Row>
          :
            <Row xs={1} sm={1} md={2} lg={3} xl={3} xxl={4} className="g-4">
              {nfts.filter(nft => filter ? nft.address === filter : true ).map((nft, i) => (
              <Col>
              <Card style={{cursor: 'pointer'}} onClick={() => handleClick(nft)} key={i}>
                <Card.Img variant="top" src={nft.image} />
                <Card.Body>
                  <Card.Title>{nft.name}</Card.Title>
                </Card.Body>
              </Card>
              </Col>
              ))}
            </Row>
        }
        <Row className="justify-content-center mt-5 mb-5">
          <Spinner style={{display: isLoading && account ? 'block' : 'none'}} animation="border" role="status" />
        </Row>
      </Col>
      <ViewerModal show={show} handleClose={handleClose} nft={viewNft} />
    </Row>
    </>
  )
}