import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Image from 'react-bootstrap/Image';
import Card from 'react-bootstrap/Card';
import { useNavigate } from 'react-router-dom';
import ContractData from '../abi';

let drops = [];
let completedDrops = [];
Object.keys(ContractData).map((contract, i) => {
  if (!ContractData[contract].hidden) {
    if (ContractData[contract].soldOut) {
      completedDrops.push({ ...ContractData[contract], slug: contract })
    } else if (ContractData[contract].frontpage) {
      drops.push({ ...ContractData[contract], slug: contract })
    }
  }
})
export default function Home() {
  const navigate = useNavigate();

  return(
    <>
    <Row className="justify-content-center mt-3">
      <Col sm={12} md={3} style={{textAlign: 'center'}}>
        <Image src="/images/hero.gif" height={300}/>
      </Col>
      <Col sm={12} md={4}>
        <h1 className="hero">NFTs for all.</h1>
        <p>NFTLaunch.me aims to democratize the launching and collecting of NFTs, for participants of all walks of life - all over the world.</p>
        <p>Built on the Cronos Chain. Operated by CronoGods.</p>
        <Button onClick={() => window.location = "https://forms.gle/HTSeqLsFenN7bD2U6"}>Apply</Button>
      </Col>
    </Row>
    <Row className="justify-content-center mt-2 mb-5">
      <h2 style={{textAlign: 'center', fontSize:'20px'}}>🔥 Featured Drop 🔥</h2>
      <Col xs={12} sm={6} md={12} lg={6}>
        <Card style={{boxShadow: "rgba(0, 0, 0, 0.24) 3px 3px 8px"}} style={{cursor: 'pointer'}} onClick={() => navigate(`/collection/sexypixelcows`)}>
          <Card.Img onClick={() => navigate(`/collection/mogulproject`)} style={{cursor: 'pointer'}} variant="top" src={`/assets/sexypixelcows/home_banner.jpg`} />
          <Card.Body>
            <Card.Title>Sexy Pixel Cows</Card.Title>
            <Card.Text>
            A collection of 10,000 manatees each with a letter that has a special purpose.
            </Card.Text>
            <Row xs={6} className="justify-content-center">
            </Row>
          </Card.Body>
        </Card>
        <Card className="mt-4" style={{cursor: 'pointer'}} onClick={() => navigate(`/collection/mafia`)}>
          <Card.Img onClick={() => navigate(`/collection/mafia`)} style={{cursor: 'pointer'}} variant="top" src={`/assets/mafia/home_banner.png`} />
          <Card.Body>
            <Card.Title>🌹MAFIA</Card.Title>
            <Card.Text>
            555 unique Mafia NFTs created with proven foundations from the best in the space. Welcome to the family.
            </Card.Text>
            <Row xs={6} className="justify-content-center">
            </Row>
          </Card.Body>
        </Card>
        

        <h2 className="mt-4"style={{textAlign: 'center', fontSize:'20px'}}>Hot Drops</h2>
        <Row className="mt-1" xs={1} sm={1} md={3} lg={3}>
          {drops.map((contract, i) => (
            <Col className="mt-3" key={i}>
              <Card>
                <Card.Img style={{cursor: 'pointer'}} onClick={() => navigate(`/collection/${contract.slug}`)} variant="top" src={`/assets/${contract.slug}/${contract.thumbnail}`} />
                <Card.Body style={{cursor: 'pointer'}} onClick={() => navigate(`/collection/${contract.slug}`)}>
                  <Card.Title>{contract.title}</Card.Title>
                  <Card.Text>
                    {contract.shortDescription}
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </Col>
      <Row className="mt-4 justify-content-center" xs={1} lg={6}>
          <Button onClick={() => navigate('/drops')}>More Drops</Button>
      </Row>

      {/* <h2 style={{textAlign: 'center'}} className="mt-5">Completed Drops</h2>
      <Col xs={12} sm={6} md={12} lg={6}>
        <Row className="mt-4" xs={1} sm={1} md={3} lg={3}>
          {completedDrops.map((contract, i) => (
            <Col className="mt-3" key={i}>
              <Card>
                <Card.Img style={{cursor: 'pointer'}} onClick={() => navigate(`/collection/${contract.slug}`)} variant="top" src={`/assets/${contract.slug}/${contract.thumbnail}`} />
                <Card.Body>
                  <Card.Title>{contract.title}</Card.Title>
                  <Card.Text>
                    {contract.shortDescription}
                  </Card.Text>
                  <Button disabled variant="primary" onClick={() => navigate(`/collection/${contract.slug}`)}>Mint</Button>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </Col> */}

    </Row>
    </>
  )
}