import { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Modal from 'react-bootstrap/Modal';
import ProgressBar from 'react-bootstrap/ProgressBar';
import ListGroup from 'react-bootstrap/ListGroup';
import Form from 'react-bootstrap/Form';
import Image from 'react-bootstrap/Image';

import { Discord, Globe, Twitter, ReceiptCutoff } from 'react-bootstrap-icons';

import Countdown from '../components/Countdown';
import BackButton from '../components/BackButton';
import ToastContainer from 'react-bootstrap/ToastContainer';
import Toast from 'react-bootstrap/Toast';
import { utils } from 'ethers';
import { useContractFunction, useEthers } from '@usedapp/core';
import { useGetMaxTokens, useGetTotalSupply, useGetIsDiscount } from '../hooks';

import { Interface } from '@ethersproject/abi';
import { Contract } from '@ethersproject/contracts';

import ContractData from '../abi';

export default function Collection() {
  const { account, chainId } = useEthers()
  const { name } = useParams();

  const [isCronosNetwork, setIsCronosNetwork] = useState(true);
  const [firstAgreement, setFirstAgreement] = useState(false);
  const [secondAgreement, setSecondAgreement] = useState(false);

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    setIsCronosNetwork(!chainId || chainId === ((process.env.NODE_ENV === 'development') ? 338 : 25))
  }, [chainId])

  const nftInterface = new Interface(ContractData[name].abi);
  let address = (process.env.NODE_ENV === 'development') ? ContractData[name].testnetAddress : ContractData[name].address
  let nftContract = new Contract(address, nftInterface);

  const [mintCount, setMintCount] = useState(1);
  const [insufficientFundsShow, setInsufficientFundsShow] = useState(false);
  const date = new Date(ContractData[name].launchDate);
  const isDiscount = useGetIsDiscount(address, nftInterface, account)[0];
  const price = ContractData[name].price;
  const discountPrice = ContractData[name].discountPrice;
  const maxTokens = ContractData[name].maxTokens;
  const totalSupply = useGetTotalSupply(address, nftInterface);
  const progress = ((totalSupply/maxTokens) * 100).toFixed(2);
  const isLaunched = +date - +new Date() < 0

  const { state, send } = useContractFunction(nftContract, "mint", { transactionName: "Minting" });

  const mint = async () => {
    await send(mintCount, { value: utils.parseEther((parseInt(isDiscount ? discountPrice : price) * mintCount).toString()) })
    handleClose()
  }

  useEffect(async () => {
    if (state.status === 'Exception' || state.status === 'Fail') {
      if(state.errorMessage.includes('insufficient balance')) {
        setInsufficientFundsShow(true);
      }
    }
  }, [state])

  return(
    <>
    {!isCronosNetwork || !account ?
    <Row className="justify-content-center mt-3">
      <Col sm={8}>
        <Alert variant='danger'>
          Connect {!account ? 'your wallet' : 'to Cronos Network' }
        </Alert>
      </Col>
    </Row>
    : ''
    }
    <BackButton />
    <Row className="justify-content-center mt-1 mb-5">
      <Col sm={12} md={4}>
        <div>
          <h1 style={{textTransform:'capitalize', marginBottom: '0'}}>{ContractData[name].title}</h1>
          <Row className="mb-3 mt-1">
            {ContractData[name].website ?
            <Col xs="auto">
              <Globe onClick={() => window.open(ContractData[name].website, '_blank')} style={{cursor: 'pointer'}}/>
            </Col> : ''}
            {ContractData[name].discord ?
            <Col xs="auto" >
              <Discord onClick={() => window.open(ContractData[name].discord, '_blank')} style={{cursor: 'pointer'}}/>
            </Col> : ''}
            {ContractData[name].twitter ?
            <Col xs="auto">
              <Twitter  onClick={() => window.open(ContractData[name].twitter, '_blank')} style={{cursor: 'pointer'}}/>
            </Col> : ''}
            {isLaunched ?
            <Col xs="auto">
              <ReceiptCutoff onClick={() => window.open(`https://cronoscan.com/token/${ContractData[name].address}`, '_blank')} style={{cursor: 'pointer'}}/>
            </Col> : ''}
          </Row>
        </div>
        <p dangerouslySetInnerHTML={{__html: ContractData[name].description}}></p>
        <ListGroup horizontal>
          <ListGroup.Item>{maxTokens} Items</ListGroup.Item>
          <ListGroup.Item>{isDiscount ? discountPrice : price} CRO</ListGroup.Item>
        </ListGroup>
        { totalSupply === maxTokens
          ? ''
          : <b><span><span style={{display: isLaunched ? 'none' : 'inline'}}>Drops in</span> <Countdown date={date} /></span></b>
        }


        {account && isLaunched
          ? <Row className="align-items-center mb-4">
              <Col xs="auto">
                {/* <Button disabled={totalSupply === maxTokens} onClick={async () => await send(mintCount, { value: utils.parseEther((parseInt(isDiscount ? discountPrice : price) * mintCount).toString()) })}>{isDiscount ? 'Whitelist Mint' : 'Mint'}</Button> */}
                <Button disabled={totalSupply === maxTokens} variant="primary" onClick={handleShow}>{isDiscount ? 'Whitelist Mint' : 'Mint'}</Button>
              </Col>
              {/* <Col xs="auto">
                <Button onClick={async () => await send(mintCount, { value: utils.parseEther((parseInt(discountPrice) * mintCount).toString()) })}>WL Mint</Button>
              </Col> */}

              <Col xs="auto">
                <Form>
                  <Form.Group className="" controlId="exampleForm.ControlInput1">
                    <Form.Control onChange={event => setMintCount(event.target.value)} type="number" style={{textAlign: 'center', width: '60px'}} min="1" max={ContractData[name].max} placeholder={mintCount} />
                  </Form.Group>
                </Form>
              </Col>
            </Row>
          : <></>
        }


        {isLaunched && typeof totalSupply === 'number'
          ? <Row className="mt-3 mb-5">
              <Col>
              <small>Mint Progress</small>
              <ProgressBar animated variant="success" now={progress} />
              <small style={{float: 'right'}}>{totalSupply}/{maxTokens} ({progress}%)</small>
              </Col>
            </Row>
          : <></>
        }


      </Col>
      <Col sm={12} md={4} className="order-first order-sm-last">
        <Row className="justify-content-center mb-5">
        <Image className="dropimg" width={300} src={`/assets/${name}/${name}.gif`} rounded />
        </Row>
      </Col>
      <ToastContainer position="top-end" className="p-3 top-index">
        <Toast onClose={() => setInsufficientFundsShow(false)} show={insufficientFundsShow} delay={5000} autohide  bg={'danger'}>
          <Toast.Header>
            <strong className="me-auto">You have insufficient funds to mint.</strong>
          </Toast.Header>
        </Toast>
      </ToastContainer>
      <Modal show={show} centered onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Terms and Conditions</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Check checked={firstAgreement} id="agreement1" onClick={(e) => { setFirstAgreement(e.target.checked);}} aria-label="option 1" label="I agree to NFTLaunch's Terms and Conditions."></Form.Check>
            <Form.Check checked={secondAgreement} id="agreement2" onClick={(e) => { setSecondAgreement(e.target.checked);}} aria-label="option 2" label="I understand the risks associated with purchasing digital assets such as NFTs."/>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button disabled={!(firstAgreement && secondAgreement)} variant="primary" onClick={mint}>
            Mint
          </Button>
        </Modal.Footer>
      </Modal>
    </Row>
    </>
  )
}