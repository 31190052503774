import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';

export default function BackButton() {
  return (
    <Row className="justify-content-center mt-5">
      <Row className="justify-content-center mb-5">
        <Col xs={12} sm={12} md={8} lg={8}>
          <Button onClick={ () => window.history.back() } variant="outline-primary">&#5176; Back</Button>
        </Col>
      </Row>
    </Row>
  )
}