import { useContractCall, useContractCalls, useContractFunction, useCalls } from "@usedapp/core";
import { formatUnits } from "@ethersproject/units";
import { Contract } from '@ethersproject/contracts';
import axios from 'axios';

export function useGetMaxTokens(nftContractAddress, nftInterface) {
  const maxTokens = useContractCall({
    abi: nftInterface,
    address: nftContractAddress,
    method: 'MAX_TOKENS',
    args: []
  }) ?? [];

  return maxTokens;
}

export function useGetIsDiscount(nftContractAddress, nftInterface, address) {
  const isDiscount = useContractCall({
    abi: nftInterface,
    address: nftContractAddress,
    method: 'isDiscount',
    args: [address]
  }) ?? [];

  return isDiscount;
}

export function useGetTotalSupply(nftContractAddress, nftInterface) {
  let totalSupply = useContractCall({
    abi: nftInterface,
    address: nftContractAddress,
    method: 'totalSupply',
    args: []
  }) ?? [];

  if (totalSupply[0]) {
    totalSupply = parseFloat(formatUnits(totalSupply[0], 0))
  }

  return totalSupply;
}
