import React from 'react'

export const ShareIcon = () => (
  <svg viewBox="0 0 10 11" fill="none" color="currentColor">
    <path
      fill="currentColor"
      color="currentColor"
      d="M8.14394 6.45953H7.76512C7.70979 6.45953 7.66439 6.47844 7.62907 6.51643C7.59345 6.55419 7.57573 6.6026 7.57573 6.66155V8.68174C7.57573 8.95953 7.48307 9.19728 7.29764 9.39512C7.11221 9.59291 6.88923 9.69174 6.6288 9.69174H1.70451C1.4441 9.69174 1.22114 9.59291 1.03567 9.39512C0.850242 9.19731 0.757538 8.95956 0.757538 8.68174V3.42919C0.757538 3.15142 0.850222 2.91369 1.03567 2.71587C1.22114 2.51803 1.4441 2.41917 1.70451 2.41917H4.22535C4.28068 2.41917 4.32608 2.40018 4.36155 2.36234C4.39702 2.32446 4.41474 2.2761 4.41474 2.21713V1.81303C4.41474 1.75417 4.39702 1.70572 4.36155 1.66791C4.32608 1.63003 4.28068 1.61113 4.22535 1.61113H1.70451C1.23501 1.61113 0.833513 1.78895 0.50005 2.14458C0.166711 2.50019 0 2.92846 0 3.42926V8.68183C0 9.1826 0.166711 9.61094 0.500071 9.96643C0.833534 10.3221 1.23503 10.5 1.70453 10.5H6.6288C7.09828 10.5 7.49982 10.3221 7.83324 9.96643C8.16668 9.61094 8.33339 9.18262 8.33339 8.68183V6.6617C8.33339 6.60269 8.31565 6.55426 8.28003 6.51643C8.24452 6.47844 8.19912 6.45953 8.14394 6.45953Z"
    />
    <path
      fill="currentColor"
      color="currentColor"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.40766 1.26336V0.5L10 0.5L10 5.19992H9.25411V1.26336H5.40766Z"
    />
    <path
      fill="currentColor"
      color="currentColor"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.76725 0.749511C9.90926 0.894841 9.90926 1.13047 9.76725 1.2758L3.89336 7.28728C3.75136 7.43261 3.52112 7.43261 3.37912 7.28728C3.23712 7.14195 3.23712 6.90633 3.37912 6.761L9.25301 0.749511C9.39502 0.604182 9.62525 0.604182 9.76725 0.749511Z"
    />
  </svg>
)